// Core

@import 'argon-dashboard/custom/functions';
@import 'argon-dashboard/custom/variables';
@import 'argon-dashboard/custom/mixins';

// bootstrap (4.6.0) components

@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

// Utilities and components

@import 'argon-dashboard/custom/reboot';
@import 'argon-dashboard/custom/utilities';
@import 'argon-dashboard/custom/components';

// Vendor (Plugins)

@import 'argon-dashboard/custom/vendors';

// Docs components

@import 'argon-dashboard/docs/variables';
@import 'argon-dashboard/docs/nav';
@import 'argon-dashboard/docs/clipboard-js';
@import 'argon-dashboard/docs/component-examples';
@import 'argon-dashboard/docs/prism';
@import 'argon-dashboard/docs/content';
@import 'argon-dashboard/docs/sidebar';
@import 'argon-dashboard/docs/footer';

// React Differences
@import 'react/react-differences';

// Manual styles

.rc-time-picker-input {
  width: 100% !important;
  padding-left: 0 !important;
  padding: 0.625rem 0.75rem !important;
  margin-top: -3px !important;
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #8898aa !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: none !important;
  box-shadow: none !important;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) !important;
}

.rc-time-picker-clear {
  top: 10px !important;
}

.img-invert-color {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

select {
  -webkit-appearance: none;
}

.folderDelete {
  margin: 0 auto !important;
  width: 90%;
}

.hover-transition-disabled {
  &:hover {
    transition: none !important;
    transform: none !important;
  }
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.btn-circle.btn-md.btn-md,
.btn-circle.btn-md-warning,
.btn-circle.btn-md-success {
  width: 70px;
  height: 70px;
  padding: 12px 15px;
  border-radius: 35px;
  font-size: 1.25rem;
  text-align: center;
}
.btn-circle.btn-md {
  background-color: #1172ef;
}

.btn-circle.btn-md-warning {
  background-color: #f7c008;
  border-color: #f7c008;
}

.buttonsNumbers .col .btn {
  margin: 25px;
}

.waiting-list-btn-submit button {
  margin: 0 auto;
}

@media only screen and (min-width: 990px) {
  .waiting-list-btn-delete {
    margin-top: 32px;
  }
}

.GoogleReCaptcha-col div {
  margin: 0 auto;
}

.GoogleReCaptcha-col {
  padding-bottom: 10px;
}
